import React from 'react';
import Layout from '../components/Layout';
import { css } from '@emotion/core';
import Helmet from 'react-helmet';
import { LiveMessage } from 'react-aria-live';

const disclaimerStyle = css`
  font-size: 1.5em;
  ul {
    list-style: circle;
    margin-left: 1em;
    li {
      margin-top: 0.6em;
    }
  }
`;

const Disclaimer = () => (
  <Layout>
    <LiveMessage message="Navigated to disclaimer" aria-live="assertive" />
    <Helmet>
      <title>Vestiges of War | Disclaimer</title>
    </Helmet>
    <section css={disclaimerStyle}>
      <h2>Disclaimer</h2>
      <p>
        This website exists to provide historical and educational information.
        The website has no affiliation with any future, present or past
        political parties, military organizations or religions.
      </p>
      <ul>
        <li>
          All information presented on this website is provided for educational
          and research purposes only.
        </li>
        <li>
          Our intention is to present genuine historical items supported by
          facts, however, due to their historical origin some items contain
          meaning and symbolism that you may find offensive. If this is the
          case, please heed this warning and refrain from further using this
          website.
        </li>
        <li>
          We strongly condemn any political viewpoint or military action that
          leads to suffering and death. However, we believe that history should
          be presented in its entirety and not forgotten.
        </li>
        <li>
          We deeply honour and respect those individuals who suffered, fought
          and died to rid us from tyranny and oppression in World War II and in
          all the great conflicts in human history.
        </li>
      </ul>
    </section>
  </Layout>
);

export default Disclaimer;
